<script setup lang="ts">
import LandingLayoutHeader from './LandingLayoutHeader.vue';
import LandingLayoutFooter from './LandingLayoutFooter.vue';
import { type LinkWithTitle } from '@glow/shared/utils/types';

const props = withDefaults(
  defineProps<{
    navigationLinks: LinkWithTitle[];
    additionalLinks: LinkWithTitle[];
    headerAuthButtonTitle?: string;
    headerMainLink: LinkWithTitle;
    headerMainLinkAuthenticatedOnly?: boolean;
  }>(),
  {
    headerMainLinkAuthenticatedOnly: true,
    headerAuthButtonTitle: 'Войти',
  }
);

const isHeaderVisible = useHideOnScroll({ startOffset: 75, threshold: 40 });
</script>

<template>
  <main class="content">
    <LandingLayoutHeader
      class="header transition-md"
      :class="{ header_hidden: !isHeaderVisible }"
      :navigation-links="props.navigationLinks"
      :additional-links="props.additionalLinks"
      :auth-button-title="props.headerAuthButtonTitle"
      :main-link="props.headerMainLink"
      :main-link-authenticated-only="props.headerMainLinkAuthenticatedOnly"
    />

    <slot></slot>

    <LandingLayoutFooter :navigation-links="props.navigationLinks" />
  </main>
</template>

<style scoped lang="scss">
.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;

  &_hidden {
    transform: translateY(-100%);
  }
}

.content {
  padding-top: 98px;
  background: #fff;
}
</style>
